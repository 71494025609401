import { FC, } from "react"
import Header from './Header';
import Footer from '../Component/Footer';

const AboutUs: FC = () => {

      return (
            <>
                  <Header />
                  <div style={{ margin: '10%' }}>
                        <h1>About Us</h1>
                        <section>
                              <p>Welcome to VirtuaSearch, the premier search engine tool that leverages cutting-edge optimization algorithms and strategic affiliate associations to deliver unparalleled search results across global markets.</p>
                              <p>At VirtuaSearch, we are committed to providing you with the best possible search experience, combining technological innovation with a vast network of affiliate partnerships to ensure that you receive optimal results tailored to your needs.</p>
                              <p>Our team of experts is dedicated to refining and advancing our search engine technology, staying ahead in the dynamic landscape of online information retrieval. We take pride in our ability to navigate the complexities of the digital world to deliver top-notch results.</p>
                              <p>Whether you're searching for information, products, or services, VirtuaSearch is your go-to tool for efficient, accurate, and personalized results. Join us on our journey to redefine the search experience and connect users with the information they seek.</p>
                        </section>
                  </div>
                  <Footer />
            </>
      )
}
export default AboutUs;