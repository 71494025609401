import { FC, } from "react"
import Header from './Header';
import Footer from './Footer';

const AboutUs: FC = () => {

      return (
            <>
                  <Header />
                  <div style={{ margin: '10%' }}>
                        <div>
                              <h3>GINETWORK LTD</h3>
                              <p>Address: Ha-Gefen St 29, Netanya, Israel</p>
                              <p>Postal Code: 4221829</p>
                              <p>Phone: 0525318745</p>
                              <p>Email: mail@ginetwork.co</p>
                              <p>
                                    Website: <a href="http://ginetwork.co">http://ginetwork.co</a>
                              </p>
                        </div>
                  </div>
                  <Footer />
            </>
      )
}
export default AboutUs;