import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./App.css";
import PagePrivacyPolicy from "./Component/PagePrivacyPolicy";
import TermsOfUse from "./Component/TermsOfUse";
import AboutUs from "./Component/AboutUs";
import Contact from "./Component/Contact";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/results" element={<App />} />
      <Route path="/Privacy-Policy" element={<PagePrivacyPolicy />} />
      <Route path="/Terms-Of-Use" element={<TermsOfUse />} />
      <Route path="/About-Us" element={<AboutUs />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
