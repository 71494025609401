import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="footer-links">
        <a href="/Privacy-Policy">PrivacyPolicy</a>
        <a href="/terms-of-use">Terms&Conditions</a>
        <a href="/About-Us">About</a>
        <a href="/contact">Contact</a>
      </div>
    </footer>
  );
};

export default Footer;