import React from 'react';
import logo4Image from "../img/virtua-search-high-resolution-logo-white-transparent.png"

interface HeaderProps {
}

const Header: React.FC<HeaderProps> = () => {
  return (
    <header>
      <a href='/'><img src={logo4Image} alt="Virtua Search" className='mlogo' /></a>
    </header>
  );
};
//
export default Header;





