import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SearchResultsComponent from "./Component/SearchResultsComponent";
import Footer from "./Component/Footer";
import logo4Image from "./img/virtua-search-high-resolution-logo-black.png";

interface SearchResultData {
  organic: string[];
  sponsored: string[];
  products: string[];
  related: string[];
}

const App: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParam = queryParams.get("query") || "";

  const [results, setResults] = useState<SearchResultData>({
    organic: [],
    sponsored: [],
    products: [],
    related: [],
  });
  const [query, setQuery] = useState(queryParam);
  const [nextToken, setNextToken] = useState<string | null>(null);

  const resetSearchResults = () => {
    setResults({
      organic: [],
      sponsored: [],
      products: [],
      related: [],
    });
    setNextToken(null);
  };

  const handleSearch = async () => {
    try {
      if (!query) return; // Do not proceed with an empty query
      const queryParams = new URLSearchParams(location.search);
      const tidParam = queryParams.get("tid") || "";
      const clickidParam = queryParams.get("clickid") || "";
      const url = queryParams.get("postback")
        ? String(queryParams.get("postback"))
        : nextToken
          ? `https://api.xyz.com/sandbox?token=&organic=5&enableProducts=1&q=${query}&tid=${tidParam}&clickid=${clickidParam}&pageToken=${nextToken}`
          : `https://api.xyz.com/sandbox?token=&organic=5&enableProducts=1&q=${query}&tid=${tidParam}&clickid=${clickidParam}`;

      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        setResults((prevResults) => ({
          organic: [...prevResults.organic, ...data.organic],
          sponsored: [...prevResults.sponsored, ...data.sponsored],
          products: [...prevResults.products, ...data.products],
          related: [...prevResults.related, ...data.related],
        }));
        setNextToken(
          data.props && data.props.nextPageToken
            ? data.props.nextPageToken
            : null
        );
      } else {
        console.error("Error fetching data");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

    if (!queryParams.get("postback")) {
      window.history.pushState(null, "", `/results?query=${query}`);
    }

    const navEvent = new Event("navigation");
    window.dispatchEvent(navEvent);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      resetSearchResults();
      handleSearch();
    }
  };

  const setRelatedValues = async (querys: any) => {
    resetSearchResults();
    setQuery(querys);
    handleSearch();
  };

  useEffect(() => {
    if (query !== undefined) {
      resetSearchResults();
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="header"
        style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      >
        <a href="/">
          <img src={logo4Image} alt="Virtua Search" className="mlogo" />
        </a>

        <div className="div-io">
          <input
            type="text"
            placeholder="Enter your search here"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
          <button onClick={handleSearch} style={{ cursor: "pointer" }}>
            {/* prettier-ignore */}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /></svg>
          </button>
        </div>
      </div>
      <SearchResultsComponent
        results={results}
        setRelatedValues={setRelatedValues}
        q={query}
      />
      {nextToken && (
        <div style={{ textAlign: "center", padding: "1rem" }}>
          <button
            style={{
              cursor: "pointer",
              padding: "10px 20px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#fff",
              fontSize: "16px",
            }}
            onClick={() => {
              handleSearch(); // You can also create a separate function for loading more results
            }}
          >
            Load More...
          </button>
        </div>
      )}
      <Footer />
    </>
  );
};

export default App;
